import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Admin from "./pages/admin/Admin";
import User from "./pages/User";
import Error404 from "./pages/404";
import Error500 from "./pages/500";
import MyPageAdmin from "./pages/admin/MyPageAdmin";
import MyPage from "./pages/MyPage";
import VideoList from "./pages/VideoList";
import UserList from "./pages/admin/UserList";
import LectureList from "./pages/admin/LectureList";
import ActionList from "./pages/ActionList";
import { getInternal } from "./api/fetch";
import { SessionType } from ".";
import UserLectureList from "./pages/admin/UserLectureList";

export default function Router(prop: { session: SessionType }) {
  if (prop.session.admin) {
    return <AdminRouter />;
  }
  if (prop.session.user) {
    return <UserRouter />;
  }
  return <NoLogin />;
}

function AdminRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route element={<Admin />}>
          <Route path="/" element={<MyPageAdmin />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/users/:userId/lectures" element={<UserLectureList />} />
          <Route path="/lectures" element={<LectureList />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

function UserRouter() {
  const [lectureData, setLectureData] = useState<any[]>([]);
  useEffect(() => {
    getInternal(`/api/login/lectures`)
      .then((res) => res.json())
      .then((content) => {
        // console.log(content.message);
        setLectureData(content.lectures);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route element={<User />}>
          <Route path="/" element={<MyPage />} />
          {lectureData.map((row: any) => (
            <Route
              key={row.id}
              path={"/lecture/" + row.id}
              element={<VideoList id={row.id} lectureName={row.lectureName} />}
            />
          ))}
          <Route path="/actions" element={<ActionList />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

function NoLogin() {
  return (
    <BrowserRouter>
      <Login />
    </BrowserRouter>
  );
}
