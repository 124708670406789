
/**
 *  yyyy/mm/dd hh:MM の形式で日付を返す
 *  null or - の場合は - を返す
 * 
 * @param dateString dateで認識できる形式のもの
 * @returns 
 */
export function formatDate(dateString: string | number | Date) {
  if (dateString === null || dateString === "-") {
    return '-';
  }
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

/**
 *  yyyy/mm/ddの形式で日付を返す
 *  null or - の場合は - を返す
 * 
 * @param dateString dateで認識できる形式のもの
 * @returns 
 */
export function formatDateSimple(dateString: string | number | Date) {
  if (dateString === null || dateString === "-") {
    return '-';
  }
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);

  return `${year}/${month}/${day}`;
}

export function calculateDiff(from: string, to: string) {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  return toDate.getTime() - fromDate.getTime();
}

export function toStringMinutes(time: number) {
  const hours = Math.floor(time / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}時間${minutes}分`;
}