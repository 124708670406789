import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListAltIcon from "@mui/icons-material/ListAlt";

const Color = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));

export default function MyPageAdmin() {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1000,
        bgcolor: "background.paper",
        mr: 4,
        ml: 4,
      }}
    >
      <Typography
        sx={{ mt: 4, mb: 2, mr: 4, ml: 4, fontWeight: "bold" }}
        variant="h4"
        component="div"
      >
        My Page
      </Typography>
      <Card variant="outlined" sx={{ mr: 4, ml: 4, borderRadius: "20px" }}>
        <CardContent>
          <Color>
            <List>
              <ListItem key={"User List"} disablePadding>
                <ListItemButton component={Link} to="/users">
                  <ListAltIcon />
                  <ListItemText primary={"受講者一覧"} />
                  <KeyboardArrowRightIcon />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Lecture List"} disablePadding>
                <ListItemButton component={Link} to="/lectures">
                  <ListAltIcon />
                  <ListItemText primary={"講座一覧"} />
                  <KeyboardArrowRightIcon />
                </ListItemButton>
              </ListItem>
            </List>
          </Color>
        </CardContent>
      </Card>
    </Box>
  );
}
