import React, { useState, useEffect, useCallback } from "react";
import LectureTable from "../../components/admin/LectureTable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAdminFetch } from "../../api/fetch";
import { useParams } from "react-router-dom";

type UserLog = {
  userName: string;
  email: string;
  log: any[];
};

export default function UserLectureList() {
  const { userId } = useParams();
  const [userLog, setUserLog] = useState<UserLog | null>(null);
  const { get } = useAdminFetch();

  const refresh = useCallback(async () => {
    const response = await get<{
      userName: string;
      email: string;
      log: any;
    }>(`/api/admin/user/` + userId);
    setUserLog({
      userName: response.userName,
      email: response.email,
      log: response.log,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userLog) {
    return <div>loading...</div>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <Box sx={{ display: "flex"}}>
        <Typography
          sx={{ fontWeight: "bold", width: "85%" }}
          variant="h4"
          component="div"
        >
          ユーザー名: {userLog.userName}（メールアドレス：{userLog.email}）
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <LectureTable log={userLog.log} />
      </Box>
    </Box>
  );
}
