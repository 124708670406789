import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import styled from "@mui/material/styles/styled";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TableFooter } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useAdminFetch } from "../../api/fetch";
import { StyledTextField } from "../../components/StyledTextField";
import { StyledFormControl } from "../../components/StyledFormControl";
import { Alert } from "../../components/admin/Alert";
import { useNavigate } from "react-router";

const LogDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const UserAddDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    maxHeight: "550px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export default function UserList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lectureData, setLectureData] = useState([]);

  const { get, post } = useAdminFetch();

  const [data, setData] = useState([]);

  const refresh = useCallback(async () => {
    const result = await get<{
      users: any;
      lectures: any;
    }>(`/api/admin/users`);
    setData(result.users);
    setLectureData(result.lectures);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [openActionLog, setOpenActionLog] = useState(false);
  const [actionLog, setActionLog] = useState<any>([]);

  const handleClickOpenActionLog = async (
    id: string,
    userName: string,
    email: string
  ) => {
    setOpenActionLog(true);
    setUserName(userName);
    setUserEmail(email);
    try {
      const actionLogs = await ActionLogData(id);
      if (actionLogs) {
        setActionLog(actionLogs);
      } else {
        setActionLog([]);
      }
    } catch (error) {
      alert("Can't get log data from db properly.");
      setActionLog([]);
    }
  }

  const handleClickCloseActionLog = () => {
    setOpenActionLog(false);
  };

  const ActionLogData = async (id: string) => {
    try {
      const response = await get<{
        data: any[];
        message: string;
      }>(`/api/admin/user/action/` + id);
      console.log(response.message);
      return response.data;
    } catch (error) {
      alert("Can't get data from db properly.");
      return null;
    }
  }

  const tbody = data ? (
    data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: any) => {
        const lectureNames = row.lectures
          .map((lecture: any) => lecture.name)
          .join(", ");
        return (
          <TableRow key={row.id}>
            <TableCell align="center" sx={{ width: "20%" }}>
              {row.userName}
            </TableCell>
            <TableCell align="center" sx={{ width: "20%" }}>
              {row.email}
            </TableCell>
            <TableCell align="center" sx={{ width: "30%" }}>
              {lectureNames}
            </TableCell>
            <TableCell align="center" sx={{ width: "15%" }}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/users/${row.id}/lectures`)
                }
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#80c3c6",
                  "&:hover": {
                    backgroundColor: "#80c3c6",
                  },
                }}
              >
                <TaskOutlinedIcon />
              </Button>
            </TableCell>
            <TableCell align="center" sx={{ width: "15%" }}>
              <Button
                variant="contained"
                onClick={() =>
                  handleClickOpenActionLog(row.id, row.userName, row.email)
                }
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#80c3c6",
                  "&:hover": {
                    backgroundColor: "#80c3c6",
                  },
                }}
              >
                <AccessTimeIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        データがありません
      </TableCell>
    </TableRow>
  );

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [pageActionLog, setPageActionLog] = useState(0);
  const [rowsPerPageActionLog, setRowsPerPageActionLog] = useState(5);

  const handleChangePageActionLog = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageActionLog(newPage);
  };

  const handleChangeRowsPerPageActionLog = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageActionLog(parseInt(event.target.value, 10));
    setPageActionLog(0);
  };

  const [openUserAddDialog, setOpenUserAddDialog] = useState(false);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [selectId, setSelectId] = useState<string[]>([]);
  const [createUserUserError, setCreateUserUserError] = useState("");
  const [createUserEmailError, setCreateUserEmailError] = useState("");
  const [createUserPasswordError, setCreateUserPasswordError] = useState("");
  const [createUserLectureError, setCreateUserLectureError] = useState("");
  const [createUserAlert, setCreateUserAlert] = useState(false);
  const [createUserErrorAlert, setCreateUserErrorAlert] = useState(false);
  const [createUserEmailErrorAlert, setCreateUserEmailErrorAlert] = useState(false);

  const handleOpenUserAdd = () => {
    setOpenUserAddDialog(true);
  };

  const handleCloseUserAdd = () => {
    setOpenUserAddDialog(false);
    setCreateUserAlert(false);
    setCreateUserErrorAlert(false);
    setCreateUserUserError("");
    setCreateUserEmailError("");
    setCreateUserPasswordError("");
    setCreateUserLectureError("");
  };

  const handleAddUser = async (e: any) => {
    if (user !== "" && pass !== "" && email !== "" && selectId.length !== 0) {
      const result = await post<{
        success: string;
      } >(`/api/admin/user/create`, {
        body: JSON.stringify({
            user_from_react: user,
            email_from_react: email,
            pass_from_react: pass,
            select_lecture_from_react: selectId,
            }),
      });

      if (result.success === "uc1") {
        setCreateUserAlert(true);
        setUser("");
        setPass("");
        setEmail("");
        setSelectId([]);
        refresh();
      } else if (result.success === "uc2") {
        setCreateUserEmailErrorAlert(true);
      } else {
        setCreateUserErrorAlert(true);
      }
    } else if (
      user === "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    }
  };

  const alerts = [
    {
      open: createUserAlert,
      severity: "success" as const,
      message: "受講者が正常に追加されました。",
      onClose: () => setCreateUserAlert(false)
    },
    {
      open: createUserErrorAlert,
      severity: "error" as const,
      message: "受講者を追加するのに失敗しました。",
      onClose: () => setCreateUserErrorAlert(false)
    },
    {
      open: createUserEmailErrorAlert,
      severity: "error" as const,
      message: "そのメールアドレスは既に登録されています。",
      onClose: () => setCreateUserEmailErrorAlert(false)
    }
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1200,
        bgcolor: "background.paper",
        mr: 4,
        ml: 4,
      }}
    >
      <Box sx={{ display: "flex", mt: 4, mb: 2 }}>
        <Typography
          sx={{ fontWeight: "bold", width: "85%" }}
          variant="h4"
          component="div"
        >
          受講者一覧
        </Typography>
        <Box sx={{ width: "15%" }}>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "white",
              backgroundColor: "#d7b27a",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#D7B27A",
                transform: "scale(1.025)",
              },
              fontWeight: "bold",
            }}
            onClick={handleOpenUserAdd}
          >
            <PersonAddAltOutlinedIcon sx={{ mr: 1 }} />
            受講者追加
          </Button>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead style={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              <TableCell align="center" sx={{ width: "20%" }}>
                ユーザー名
              </TableCell>
              <TableCell align="center" sx={{ width: "20%" }}>
                メールアドレス
              </TableCell>
              <TableCell align="center" sx={{ width: "30%" }}>
                登録講座
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                受講履歴
              </TableCell>
              <TableCell align="center" sx={{ width: "15%", fontSize: "12px" }}>
                操作履歴
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tbody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  { label: "100件", value: 100 },
                  { label: "全て", value: data ? data.length : 0 },
                ]}
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LogDialog
        onClose={handleClickCloseActionLog}
        aria-labelledby="log-dialog"
        open={openActionLog}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          ユーザ名：{userName}（メールアドレス：{userEmail}）
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseActionLog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ overflowY: "auto" }}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#80c3c6" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "10%" }}>
                    操作
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    詳細
                  </TableCell>
                  <TableCell align="center" sx={{ width: "10%" }}>
                    操作日時
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionLog
                  .slice(
                    pageActionLog * rowsPerPageActionLog,
                    pageActionLog * rowsPerPageActionLog +
                      rowsPerPageActionLog
                  )
                  .map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        {row.action !== null
                          ? row.action
                          : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "20%" }}>
                        {row.detail !== null
                          ? row.detail
                          : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        {row.createdAt !== null
                          ? formatDate(row.createdAt)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      { label: "50件", value: 50 },
                      {
                        label: "全て",
                        value: actionLog ? actionLog.length : 0,
                      },
                    ]}
                    count={actionLog ? actionLog.length : 0}
                    rowsPerPage={rowsPerPageActionLog}
                    page={pageActionLog}
                    onPageChange={handleChangePageActionLog}
                    onRowsPerPageChange={handleChangeRowsPerPageActionLog}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </LogDialog>
      <UserAddDialog
        open={openUserAddDialog}
        aria-labelledby="user-add-dialog"
        onClose={handleCloseUserAdd}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="user-add-dialog-title"
          align="center"
        >
          <PersonAddAltOutlinedIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          受講者追加
        </DialogTitle>
        <IconButton
          aria-label="close-user-add-dialog"
          onClick={handleCloseUserAdd}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <StyledTextField
            label="ユーザー名"
            variant="standard"
            onChange={(e) => {
              setUser(e.target.value);
              setCreateUserUserError("");
            }}
            value={user}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserUserError)}
            helperText={createUserUserError}
          />
          <StyledTextField
            label="メールアドレス"
            type="email"
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
              setCreateUserEmailError("");
            }}
            value={email}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserEmailError)}
            helperText={createUserEmailError}
          />
          <StyledTextField
            label="パスワード"
            type="password"
            variant="standard"
            onChange={(e) => {
              setPass(e.target.value);
              setCreateUserPasswordError("");
            }}
            value={pass}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserPasswordError)}
            helperText={createUserPasswordError}
          />
          <StyledFormControl
            variant="standard"
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserLectureError)}
          >
            <InputLabel>登録する講座</InputLabel>
            <Select
              multiple
              value={selectId}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setSelectId(
                  typeof value === "string" ? value.split(",") : value
                );
                setCreateUserLectureError("");
              }}
              renderValue={(selected) => `${selected.length}個選択されました`}
            >
              {lectureData
                ? lectureData.map((row: any) => (
                    <MenuItem key={row.id} value={row.id}>
                      <Checkbox
                        checked={(selectId as any[]).indexOf(row.id) > -1}
                      />
                      <ListItemText primary={row.lectureName} />
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText id="user-lecture-error">
              {createUserLectureError}
            </FormHelperText>
          </StyledFormControl>
          <Button
            variant="contained"
            onClick={handleAddUser}
            sx={{
              width: "50%",
              color: "#d7b27a",
              alignSelf: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              border: "2px solid #d7b27a",
              fontSize: "15px",
              "&:hover": {
                color: "white",
                transform: "scale(1.1)",
                backgroundColor: "#d7b27a",
              },
            }}
          >
            追加
          </Button>
        </DialogContent>
      </UserAddDialog>
      {alerts.map((alert, index) => (
        <Alert
          key={index}
          open={alert.open}
          severity={alert.severity}
          message={alert.message}
          onClose={alert.onClose}
        />
      ))}
    </Box>
  );
}
