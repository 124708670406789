import React, { useCallback, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { NavLink } from "react-router-dom";
import { getBackgroundImage, getTitle } from "../../environment";
import LogoutButton from "../../components/Logout";
import { useAdminName } from "./session";

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Admin() {
  const userName = useAdminName();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <Header
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          adminName={userName!}
        />
        <AdminDrawer open={open} handleDrawerClose={handleDrawerClose} />
        <Main open={open}>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </div>
  );
}

type HeaderProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  adminName: string;
};

const Header: React.FC<HeaderProps> = ({
  open,
  handleDrawerOpen,
  adminName,
}) => {
  return (
    <AppBar
      open={open}
      position="fixed"
      sx={{
        backgroundImage: getBackgroundImage(),
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }), width: "2%" }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, textDecoration: "none", color: "white" }}
        >
          <NavLink
            to="/"
            style={{ textDecoration: "none", color: "white" }}
          >
            {getTitle()} -ADMINISTER-
          </NavLink>
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
          {`ようこそ！${adminName}様`}
        </Typography>
        <div style={{ width: 20 }} />
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
};

type AdminDrawerProps = {
  open: boolean;
  handleDrawerClose: () => void;
};

const AdminDrawer: React.FC<AdminDrawerProps> = ({
  open,
  handleDrawerClose,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          whiteSpace: "nowrap",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Links />
    </Drawer>
  );
};

type LinkType = {
  title: string;
  icon: React.ElementType;
  uri: string;
};

const Links = () => {
  const mypage: LinkType = {
    title: "マイページ",
    icon: PersonIcon,
    uri: "/",
  };

  const items: LinkType[] = [
    {
      title: "受講者一覧",
      icon: ListAltIcon,
      uri: "/users",
    },
    {
      title: "講座一覧",
      icon: ListAltIcon,
      uri: "/lectures",
    },
  ];

  return (
    <>
      <List>
        <LinkItem {...mypage} />
      </List>
      <Divider />
      <List>
        {items.map((link) => (
          <LinkItem key={link.title} {...link} />
        ))}
      </List>
    </>
  );
};

const LinkItem = (link: LinkType) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={link.uri as string}>
        <link.icon />
        <ListItemText primary={link.title} />
      </ListItemButton>
    </ListItem>
  );
};
