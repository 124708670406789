import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import LectureTableRow from "./LectureTableRow";
import { useState } from "react";

type LectureTableProps = {
  log: any[] | null;
};

export default function LectureTable({ log }: LectureTableProps) {
  const [openDetail, setOpenDetail] = useState<any>({});
  const [pageLog, setPageLog] = useState(0);
  const [rowsPerPageLog, setRowsPerPageLog] = useState(5);

  const handleChangePageLog = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageLog(newPage);
  };

  const handleChangeRowsPerPageLog = (event: { target: { value: string } }) => {
    setRowsPerPageLog(parseInt(event.target.value, 10));
    setPageLog(0);
  };

  const toggleDetail = (id: any) => {
    setOpenDetail((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ borderRadius: "20px" }}
    >
      <Table
        sx={{
          minWidth: 300,
          [`& .${tableCellClasses.root}`]: {
            borderLeft: "none",
            borderRight: "none",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: "#80c3c6" }}>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="center" sx={{ width: "45%" }}>
              講座名
            </TableCell>
            <TableCell align="center">ステータス</TableCell>
            <TableCell align="center">修了日</TableCell>
            <TableCell align="center">学習時間</TableCell>
            <TableCell align="center">動画視聴状況</TableCell>
            <TableCell align="center">小テスト回答状況</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {log ? (
            log
              .slice(
                pageLog * rowsPerPageLog,
                pageLog * rowsPerPageLog + rowsPerPageLog
              )
              .map((row: any) => (
                <LectureTableRow
                  key={row.id}
                  row={row}
                  openDetail={openDetail}
                  toggleDetail={toggleDetail}
                />
              ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                データがありません
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="表示件数:"
              rowsPerPageOptions={[
                { label: "5件", value: 5 },
                { label: "10件", value: 10 },
                { label: "50件", value: 50 },
                { label: "全て", value: log ? log.length : 0 },
              ]}
              count={log ? log.length : 0}
              rowsPerPage={rowsPerPageLog}
              page={pageLog}
              onPageChange={handleChangePageLog}
              onRowsPerPageChange={handleChangeRowsPerPageLog}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
