import React, { useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import LightTooltip from "@mui/material/Tooltip";
import {
  calculateDiff,
  formatDate,
  formatDateSimple,
  toStringMinutes,
} from "../../util/datetime";

type LectureTableRowProps = {
  row: any;
  openDetail: any;
  toggleDetail: (id: any) => void;
  defaultOpen?: boolean;
};

export default function LectureTableRow({
  row,
  openDetail,
  toggleDetail,
  defaultOpen = true,
}: LectureTableRowProps) {
  useEffect(() => {
    if (defaultOpen && !openDetail[row.id]) {
      toggleDetail(row.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cells = calculateHeaderCellValues({
    row,
  });

  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: "#f5fafa" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleDetail(row.id)}
          >
            {openDetail[row.id] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        {cells.map((cell, index) => (
          <HeaderCell key={index} value={cell.value} width={cell.width} />
        ))}
      </TableRow>
      <Details row={row} openDetail={openDetail} />
    </React.Fragment>
  );
}

function calculateHeaderCellValues({
  row,
}: Pick<LectureTableRowProps, "row">): { value: string; width?: string }[] {
  const numberOfVideos = row.videos.length;

  const completedVideos = row.videos.filter(
    (video: { videoStatement: boolean }) => video.videoStatement === true
  ).length;

  const completedTests = row.videos.filter(
    (video: { testContent: string }) => video.testContent !== "-"
  ).length;

  const lectureStatus =
    numberOfVideos !== 0 &&
    completedVideos === numberOfVideos &&
    completedTests === numberOfVideos
      ? "完了"
      : "未完了";

  const lectureEndTime =
    lectureStatus === "完了"
      ? formatDateSimple(
          Math.max(
            ...row.videos.map((video: any) =>
              new Date(video.testTime).getTime()
            )
          )
        )
      : "-";

  const learningTime = (row.videos as any[])
    .filter(
      (video: any) => video.videoStartTime !== "-" && video.videoEndTime !== "-"
    )
    .reduce(
      (total: number, video: any) =>
        total + calculateDiff(video.videoStartTime, video.videoEndTime),
      0
    );

  const formattedLearningTime = toStringMinutes(learningTime);
  return [
    { value: row.lectureName, width: "45%" },
    { value: lectureStatus },
    { value: lectureEndTime },
    { value: formattedLearningTime },
    {
      value:
        numberOfVideos !== 0
          ? `${Math.round((completedVideos * 100) / numberOfVideos)} %`
          : "0 %",
    },
    {
      value:
        numberOfVideos !== 0
          ? `${Math.round((completedTests * 100) / numberOfVideos)} %`
          : "0 %",
    },
  ];
}

function HeaderCell({ value, width }: { value: string; width?: string }) {
  return (
    <TableCell align="center" sx={{ width: width }}>
      <Typography variant="body2" fontWeight="bold">
        {value}
      </Typography>
    </TableCell>
  );
}

type row = {
  id: number;
  videos: Video[];
};

type openDetail = any[];

function Details({ row, openDetail }: { row: row; openDetail: openDetail }) {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <Collapse in={openDetail[row.id]} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table size="small" aria-label="videos">
              <DetailHeader />
              <TableBody>
                {row.videos.map((video: any) => (
                  <DetailRow key={video.id} {...video} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

function DetailHeader() {
  return (
    <TableHead style={{ backgroundColor: "#E5E4E2" }}>
      <TableRow>
        <TableCell align="center" sx={{ width: "30%" }}>
          動画名
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          視聴状況
        </TableCell>
        <TableCell align="center" sx={{ width: "60%" }}>
          小テスト回答
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

type Video = {
  id: number;
  videoName: string;
  videoStartTime: string;
  videoEndTime: string;
  videoStatement: boolean;
  testContent: string;
  testTime: string;
};

function DetailRow(video: Video) {
  return (
    <TableRow key={video.id}>
      <TableCell align="center" sx={{ width: "30%" }}>
        <Typography variant="body2" fontWeight="bold">
          {video.videoName}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          <LightTooltip
            title={
              <React.Fragment>
                {`開始時刻: ${formatDate(video.videoStartTime)}`}
                <br />
                {`終了時刻: ${formatDate(video.videoEndTime)}`}
              </React.Fragment>
            }
            placement="right"
          >
            <span>{video.videoStatement ? <DoneIcon /> : "-"}</span>
          </LightTooltip>
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: "60%",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: 1.5,
            maxHeight: "4.5em",
            overflowY: "auto",
          }}
        >
          {video.testContent !== "-"
            ? video.testContent + "(作成日：" + formatDateSimple(video.testTime) + ")"
            : "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
