import { Typography, SxProps } from "@mui/material";

type LecturePeriodProps = {
    startAt: string,
    endAt: string,
    sx?: SxProps
};

const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}/${month}/${day}`;
};

const getPeriod = (startAt: string, endAt: string) => {
    return `受講期間：${formatDate(startAt)} ~ ${formatDate(endAt)}`;
};

export const LecturePeriod: React.FC<LecturePeriodProps> = ({
    sx,
    startAt,
    endAt,
}) => {
    return (
        <Typography 
            variant="body2"
            color="text.secondary"
            sx={sx}
        >
            {getPeriod(startAt, endAt)}
        </Typography>
    );
};
